<template lang="pug">
  .setting-item(
    :class="settingItemClasses"
  )
    transition(name="fade" appearance)
      SettingItemExpanded.expanded(
        v-if="isExpanded"
        v-bind="$props"
        :car-classes-items="carClassesItems"
        :car-classes-loading="carClassesLoading"
        :selected-days-options="selectedDaysOptions[selectedCarClassIds]"
        :is-unsaved-changes="isUnsavedChanges"
        :is-new-setting="isNewSetting"
        :is-invalid-filters="isInvalidFilters"
        :is-not-selected-days-options="isNotSelectedDaysOptions()"
        :intersections="intersections"
        :inactive-state="inactiveState"
        @switch-component="$emit('collapse')"
        @cancel-component="$emit('cancel')"
        @change-days-options="handleChangeDaysOptions"
        @select-reference="handleSelectReference"
        @select-car-classes="handleSelectCarClasses"
        @change-setting="handleChangeSetting"
        @delete-setting="handleDeleteSetting"
      )
      SettingItemCollapsed.collapsed(
        v-else
        :class="{ inactive: !setting.active && !isExpanded }"
        v-bind="$props"
        :inactive-state="inactiveState"
        :selected-days-options="selectedDaysOptions[selectedCarClassIds]"
        :is-unsaved-changes="isUnsavedChanges"
        :is-new-setting="isNewSetting"
        :is-invalid-filters="isInvalidFilters"
        @switch-component="$emit('expand')"
        @change-setting="handleChangeSetting"
        @delete-setting="handleDeleteSetting"
      )
</template>

<script>
  // misc
  import { isEmpty, isUndefined, countBy, some, values, isNil, get } from "lodash-es"
  import { DEFAULT_SETTING_OBJECT } from "@/components/InventoryManagement/SetForFutureDays/constants"
  import { api } from "@/config"
  import { getOrderedCarClassIds, getKeysByValues } from "@/helpers/auto-set-inventory"
  import { isSharedInventoryEnabled as isOrganizationWithSharedInventory } from "@/helpers/organization"

  export default {
    props: {
      setting: {
        type: Object,
        default: () => ({ ...DEFAULT_SETTING_OBJECT })
      },
      intersectionData: {
        type: Object,
        default: () => {}
      },
      isExpanded: Boolean,
      isExpandable: Boolean,
      referencesItems: Array,
      referencesLoading: Boolean,
      selectedDaysOptions: Object,
      inventoryFields: Array
    },

    components: {
      SettingItemExpanded: () => import("./SettingItemExpanded"),
      SettingItemCollapsed: () => import("./SettingItemCollapsed")
    },

    mounted() {
      const { reference } = this.setting

      if (!isEmpty(reference)) {
        this.fetchCarClassesItems(reference)
      }
    },

    data() {
      return {
        carClassesItems: [],
        carClassesLoading: false
      }
    },

    computed: {
      isOrganizationWithSharedInventory,

      settingItemClasses() {
        return {
          expanded: this.isExpanded,
          inactive: this.inactiveState.inactiveReference || this.inactiveState.inactiveAllCarClasses,
          "has-intersections": this.hasIntersections
        }
      },

      intersections() {
        return get(this.intersectionData, "intersections", {})
      },

      intersectedReferenceId() {
        return get(this.intersectionData, "referenceId")
      },

      referenceId() {
        return get(this.setting, "reference.id")
      },

      component({ isExpanded }) {
        return isExpanded ? "Expanded" : "Collapsed"
      },

      isInvalidFilters({ setting }) {
        return isEmpty(setting.reference) || isEmpty(setting.car_classes)
      },

      isNewSetting({ setting }) {
        return isUndefined(setting.id)
      },

      isUnsavedChanges({ setting }) {
        return !!setting._updated
      },

      selectedCarClassIds({ setting }) {
        return getOrderedCarClassIds(setting)
      },

      inactiveState({ setting: { reference, car_classes, synced_group } }) {
        const countOfInactiveCarClasses = countBy(car_classes, c => c.sync)[false]
        const inactiveReference = isEmpty(reference) ? false : !reference.sync
        return {
          inactiveReference,
          inactiveAllShops: synced_group === false,
          inactiveAllCarClasses: countOfInactiveCarClasses === car_classes?.length,
          inactiveSomeCarClasses: countOfInactiveCarClasses > 0,
          inactiveAll: inactiveReference && countOfInactiveCarClasses === car_classes?.length
        }
      },

      hasIntersections({ intersections }) {
        if (this.isExpanded || isEmpty(intersections) || this.intersectedReferenceId !== this.referenceId) {
          return false
        }

        return some(this.selectedCarClassIds, carClassId => {
          return !isEmpty(intersections[carClassId]) && this.checkDaysMatching(intersections[carClassId])
        })
      }
    },

    methods: {
      async fetchCarClassesItems(reference) {
        this.carClassesLoading = true

        const params = {
          pagination: { _disabled: true },
          reference_id: reference.id
        }

        const response = await api.get("/inventory_settings/car_classes", { params })

        this.carClassesItems = response.data.data.items
        this.carClassesLoading = false
      },

      handleSelectReference(reference) {
        this.handleChangeSetting({
          ...this.setting,
          reference,
          car_classes: [],
          inventory: {},
          days_options: DEFAULT_SETTING_OBJECT.days_options,
          active: false
        })

        this.fetchCarClassesItems(reference)
      },

      handleSelectCarClasses(carClasses) {
        this.handleChangeSetting({
          ...this.setting,
          car_classes: carClasses.sort((a, b) => a.order - b.order),
          active: false
        })
      },

      checkDaysMatching(days) {
        return some(days, day => this.setting.days_options[day])
      },

      handleChangeDaysOptions(daysOptions) {
        const settingObject = { ...this.setting, days_options: daysOptions }

        this.handleChangeSetting(settingObject)
      },

      isNotSelectedDaysOptions(daysOptions = this.setting.days_options) {
        return isEmpty(getKeysByValues(daysOptions))
      },

      isSomeInputsInvalid(inventory) {
        return some(values(inventory), ({ sale_stop, shop_inventory_limit, shared_inventory_limit }) => {
          if (this.isOrganizationWithSharedInventory) {
            return !sale_stop && isNil(shared_inventory_limit)
          } else {
            return !sale_stop && isNil(shop_inventory_limit)
          }
        })
      },

      handleChangeSetting(setting) {
        if (this.isNotSelectedDaysOptions(setting.days_options) || this.isSomeInputsInvalid(setting.inventory)) {
          setting.active = false
        }

        this.$emit("change-setting", setting)
      },

      handleDeleteSetting() {
        this.$emit("delete-setting", this.setting)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/auto-set-inventory-for-future.sass"

  .setting-item
    border: 1px solid $border-color
    border-radius: 3px
    height: auto
    margin-bottom: 25px
    max-height: 200px
    padding: 15px
    position: relative
    transition: 0.5s ease-in-out
    width: 100%

    &.inactive
      ::v-deep
        .app-checkbox-icon
          border-color: $default-gray !important
        .checkbox-title
          color: $default-gray !important

    &.has-intersections
     border-color: $alert-yellow-dark
     background-color: $alert-yellow

    &::-webkit-scrollbar
      width: 0

    .expanded
      position: absolute

      &.fade-leave-active
        position: absolute
        width: 100%
        left: 0
        padding: 15px

    .collapsed
      position: inherit

    &.expanded
      box-shadow: 0 2px 8px -3px $default-black
      max-height: 500px

      .expanded
        position: inherit

      .collapsed
        position: absolute
        width: 100%
        left: 0
        padding: 15px

    .inactive
      opacity: 0.75

    .fade-enter-active
      transition: opacity 0.3s ease-in-out

    .fade-enter
      opacity: 0

    .fade-leave-active
      transition: opacity .3s ease-in-out

    .fade-leave
      opacity: inherit

    .fade-leave-to
      opacity: 0
</style>
